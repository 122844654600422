<template>
  <div class="container center" style="text-align: center;">

    <div v-if="showLoader">
      <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
    </div>

    <div v-if="!showLoader">
      <div v-if="data.length===0">
        <p style="font-weight: bold; font-size: larger" v-if="language === 'en'">Oops, we are sorry. This link has
          expired, please <a :href="applyLink">apply
            again </a>. If this message seems
          to be an error please let us know. Send us an email to {{ toEmail }} to help you better</p>
        <p style="font-weight: bold; font-size: larger" v-if="language === 'fr'">Oups, nous sommes désolés. Ce lien a
          expiré, veuillez
          <a :href="applyLink"> réappliquer</a>. Si ce message
          semble être
          une erreur, veuillez nous en informer. Envoyez-nous un courriel à {{ toEmail }} pour mieux vous aider.</p>
      </div>

      <div v-if="data.length>0">

        <p style="font-weight: bold; font-size: larger" v-if="data.length>1 && language === 'en'">You have been pre-approved
          for multiple loans up to ${{ maxLoanAmount }}.
          Please select your options below; can not exceed your ${{ maxLoanAmount }} limit :</p>

        <p style="font-weight: bold; font-size: larger" v-if="data.length===1 && language === 'en'">You have been
          approved for a loan of up to
          ${{ maxLoanAmount }}. Please select your options below; can not exceed your
          ${{ maxLoanAmount }} limit :</p>

        <p style="font-weight: bold; font-size: larger" v-if="data.length>1 && language === 'fr'">Vous avez été pré-approuvé
          pour plusieurs prêts jusqu'à concurrence de {{ maxLoanAmount }}$.
          Veuillez sélectionner vos options ci-dessous. Vous ne pouvez pas dépasser votre limite de
          {{ maxLoanAmount }}$:</p>

        <p style="font-weight: bold; font-size: larger" v-if="data.length===1 && language === 'fr'">Vous avez été
          approuvé pour un prêt pouvant
          aller jusqu'à {{ maxLoanAmount }}$.
          Veuillez sélectionner vos options ci-dessous; ne peut pas dépasser votre limite de {{ maxLoanAmount }}$ :</p>

        <b-spinner v-if="loading" style="width: 3rem; height: 3rem;" label="Spinning"></b-spinner>

        <div class="row titleborder table-mmargin">

          <div
              v-bind:class="{ 'col-4': data.length === 3, 'col-6': data.length === 2, 'col-12': data.length === 1, 'header-bottom' : item.loan_status==='Signed'  }"
              class="input-div" v-for="(item, imageIndex) in data" :key="imageIndex">
            <div>
              <h4 class="title">{{ item.employer_name }}</h4>
            </div>
            <div>
              <h5 class="subtitle" v-if="item.loan_status!='Signed'">
                {{ language === 'en' ? 'Next Pay Date:' : 'Prochain Paiement:' }}
                {{ item.next_paydate }}</h5>
            </div>
          </div>
        </div>

        <div class="row itemborder table-mmargin">
          <div
              v-bind:class="{ 'col-4': data.length === 3, 'col-6': data.length === 2, 'col-12': data.length === 1, 'main-column-border': item.loan_status==='Signed' }"
              style="border-bottom:1px solid black" v-for="(item, imageIndex) in data" :key="imageIndex">
            <div v-if="item.loan_status!='Signed'">
              <div v-for="(option, optionIndex) in item.options" :key="imageIndex + optionIndex">
                <div class="row input-div">
                  <div v-bind:class="{ 'zeroWidth1': data.length === 1 }" class="zeroWidth cell-height"></div>
                  <div v-bind:class="{ 'fullWidth1': data.length === 1 }" class="fullWidth cell-height">
                    <b-form-radio :disabled="option > maxLoanAmount" v-if="option !== ''" class="radiobtn"
                                  v-model="selected[imageIndex]" :name="'group-'+imageIndex" :value="option">{{
                        option
                      }}
                      {{ option === 0 ? (language === 'en' ? '(No Loan)' : '(pas de prêt)') : '' }}
                    </b-form-radio>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="item.loan_status==='Signed'">
              <p class="amount_para" v-if=" language === 'en' ">${{ item.current_offer }}</p>
              <p class="amount_para" v-if=" language === 'fr' ">{{ item.current_offer }}$</p>
              <p style="font-weight: bold; font-size: larger;margin:0">
                <b-link :href="item.contract_pdf_link" target="blank" class="sign">
                  {{ language === 'en' ? 'Signed!' : 'Signé!' }}
                </b-link>
              </p>
            </div>
          </div>

        </div>


        <div class="row" style="margin-top: 10px" v-if="selected.length > 0">
          {{ language === 'en' ? 'Your current selection:' : 'Votre sélection actuelle:' }} {{ getCurrentSelection() }}
          = <span class="totalSum"> {{
            getTotalSelected()
          }} </span>
        </div>

        <div v-if="getTotalSelected() > maxLoanAmount" class="row" style="text-align: center; margin-top: 10px">
          <p style="width: 100%; margin-bottom: 0; font-weight: bold">Your current selection exceeds your ${{
              maxLoanAmount
            }} limit, please
            select a different amount</p>
        </div>

        <b-button size="lg" style="margin-top: 20px"
                  :disabled="getTotalSelected() > maxLoanAmount || selected.length === 0 || allSigned" class="nextBtn"
                  v-bind:class="{'btn-enabled': getTotalSelected() <= maxLoanAmount && selected.length > 0 }"
                  @click="submit()">{{ language === 'fr' ? 'Suivant' : 'Next' }}
        </b-button>

      </div>
    </div>

  </div>

</template>

<script>
const axios = require('axios');

export default {
  data() {
    return {
      loading: true,
      data: [],
      selected: [],
      maxLoanAmount: '',
      nameOfEmployer: '',
      disableButton: true,
      language: 'en',
      storefront: '',
      toEmail: '',
      applyLink: '',
      showLoader: true,
      allSigned: false
    }
  },
  methods: {
    async dataList() {
      this.showLoader = true;
      const response = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/approvedLoanOptions?token=${this.$route.query.token}`
      );
      this.showLoader = false;
      if (response && response.data) {
        this.loading = false;
        this.data = response.data.value;
        this.selected = []
        let signedCount = 0;
        for (let i = 0; i < this.data.length; i++) {
          if (this.data[i].loan_status === 'Signed') {
            this.selected[i] = (this.data[i].current_offer)
            signedCount++;
          }
        }
        if (signedCount === this.data.length) {
          this.allSigned = true
        }
        const company = response.data.companyInfo;
        this.storefront = company.name;
        this.toEmail = company.reply_to_email;
        this.maxLoanAmount = Math.max.apply(Math, this.data.map(function (o) {
          return o.max_approved_amount;
        }))
        this.nameOfEmployer = company.name
        this.language = company.language;
        this.applyLink = company.apply_link;
        this.$root.$emit('on-set-logo', {
          logo: company.logo,
          contactUs: company.contact_us_link,
          homepage_link: company.homepage_link,
          language: this.language
        });
      }
    },
    async submit() {
      let sum = 0;
      for (let i = 0; i < this.selected.length; i++) {
        sum += this.selected[i];
      }
      if (sum > this.maxLoanAmount) {
        alert('Selected options are greater than max approved amount');
        return;
      }
      if (this.selected.length && sum === 0) {
        this.$router.push({
          name: 'feedback',
          query: {token: this.$route.query.token, language: this.language, storefront: this.storefront}
        });
      }

      if (this.selected.length && sum !== 0) {
        const contracts = [];
        const offers = [];
        for (let i = 0; i < this.selected.length; i++) {
          if (this.selected[i] && this.data[i].loan_status !== 'Signed') {
            contracts.push(this.data[i].id);
            offers.push(this.selected[i]);
          }
        }
        this.$router.push({
          path: `/contract/${contracts[0]}`,
          query: {
            token: this.$route.query.token,
            language: this.language,
            storefront: this.storefront,
            contracts: contracts.join(),
            offers: offers.join()
          }
        });
      }
    },
    getCurrentSelection() {
      let str = '';
      for (let i = 0; i < this.selected.length; i++) {
        if (this.selected[i] !== undefined) {
          str += this.selected[i];
          if (i !== (this.selected.length - 1)) {
            str += ' + ';
          }
        }
      }
      return str;
    },
    getTotalSelected() {
      let total = 0;
      for (let i = 0; i < this.selected.length; i++) {
        if (this.selected[i]) {
          total += this.selected[i];
        }
      }
      return total;
    }
  },
  async mounted() {
    this.dataList();
  }
}
</script>

<style scoped>
.header-bottom {
  border-bottom: 1px solid black;
}

.main-column-border {
  border-right: 1px solid black;
  background-color: rgb(243, 243, 243);
}

.radiobtn {
  float: left;
  margin-top: 5px;
}

.input-div {
  border-right: 1px solid Black;
  border-top: 1px solid Black;
  padding: 10px 30px 10px 30px;
}

.amount_para {
  margin-top: 25%;
  margin-bottom: 0;
}

.titleborder {
  margin-top: 50px;
  border-left: 1px solid Black;
}

.itemborder {
  border-left: 1px solid Black;
}

.sign {
  color: green !important;
  text-decoration: none;
}

.table-mmargin {
  margin-left: 70px;
  margin-right: 70px
}

.cell-height {
  height: 30px;
}

.btnitem {
  font-weight: bold;
  margin-right: 30px;
}

.nextBtn {
  margin: 10px;
}

.zeroWidth {
  width: 35%;
}

.zeroWidth1 {
  width: 45%;
}

.fullWidth {
  width: 65%;
}

.fullWidth1 {
  width: 55%;
}

.totalSum {
  margin-left: 5px;
  border: 1px solid blue;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 10px;
  font-weight: bold;
}

.btn-enabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

@media only screen and (max-width: 780px) {

  .zeroWidth {
    width: 0%;
  }

  .zeroWidth1 {
    width: 0%;
  }

  .fullWidth {
    width: 100%;
  }

  .fullWidth1 {
    width: 100%;
  }

  .input-div {
    padding: 5px;
    font-size-adjust: 10px;
  }

  .title {
    font-size: 15px;
  }

  .subtitle {
    font-size: 15px;
  }

  .amount_para {
    margin-top: 30%;
  }


}

@media only screen and (max-width: 530px) {
  .table-mmargin {
    margin-left: 10px;
    margin-right: 10px
  }

  .cell-height {
    height: 47px;
  }

  .amount_para {
    margin-top: 60%;
  }
}
</style>
