<template>
  <div style="margin-top: 50px">
    <TableComponent/>
  </div>
</template>

<script>
import TableComponent from '../components/home/TableComponent.vue'

export default {
  components: {
    TableComponent
  }
}
</script>
